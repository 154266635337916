import React from 'react';
import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import configDB from '../data/customizer/config'
import PrivateRoute from './private-route';
import AppLayout from '../components/app';
import LoginPage from '../pages/login';
import FirstTimeLoginPage from '../pages/firstTimeLogin';
import ForgetPwd from '../pages/forgetPwd';
import ComingSoon from '../pages/comingsoon';
import ComingSoonImg from '../pages/comingsoonImg';
import ComingSoonVideo from '../pages/comingsoonVideo';
import Maintenance from '../pages/maintenance';
import Error400 from '../pages/errors/error400';
import Error401 from '../pages/errors/error401';
import Error403 from '../pages/errors/error403';
import Error404 from '../pages/errors/error404';
import Error500 from '../pages/errors/error500';
import Error503 from '../pages/errors/error503';
import { routes } from './layouts-routes';
import Callback from '../auth/callback';


const MainRoutes = () => {
  const jwt_token = localStorage.getItem('token');
  const first_time_login = localStorage.getItem('first_time_login');

  useEffect(() => {
    const abortController = new AbortController();
    //fetch('/users', requestOptions).then(handleResponse)
    const color = localStorage.getItem('color')
    const layout = localStorage.getItem('layout_version') || configDB.data.color.layout_version
    document.body.classList.add(layout);
    console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
    console.disableYellowBox = true;
    document.getElementById("color").setAttribute("href", `${process.env.REACT_APP_PUBLIC_URL}/assets/css/${color}.css`);

    return function cleanup() {
      abortController.abort();
    }
  }, []);

  return (
    <>
      <Routes>
      <Route exact path={`${process.env.REACT_APP_PUBLIC_URL}/`} element={<LoginPage />} />
      <Route path={`${process.env.REACT_APP_PUBLIC_URL}/first`} element={<FirstTimeLoginPage />} />
        <Route exact path='/' element={<PrivateRoute />} >
          {routes.map(({ path, Component }, i) => (
            <Route element={<AppLayout />} key={i}>
              {jwt_token && first_time_login == 1 && <Route exact
                path={`/`}
                element={<Navigate to={`${process.env.REACT_APP_PUBLIC_URL}/dashboard`} />}
              />}
              <Route exact path={path} element={Component} />
            </Route>
          ))}
        </Route>
        <Route path={`${process.env.REACT_APP_PUBLIC_URL}/forgot-password`} element={<ForgetPwd />} />
        <Route path={`${process.env.REACT_APP_PUBLIC_URL}/pages/comingsoon`} element={<ComingSoon />} />
        <Route path={`${process.env.REACT_APP_PUBLIC_URL}/pages/comingsoonImg`} element={<ComingSoonImg />} />
        <Route path={`${process.env.REACT_APP_PUBLIC_URL}/pages/comingsoonVideo`} element={<ComingSoonVideo />} />
        <Route path={`${process.env.REACT_APP_PUBLIC_URL}/pages/maintenance`} element={<Maintenance />} />
        <Route path={`${process.env.REACT_APP_PUBLIC_URL}/pages/errors/error400`} element={<Error400 />} />
        <Route path={`${process.env.REACT_APP_PUBLIC_URL}/pages/errors/error401`} element={<Error401 />} />
        <Route path={`${process.env.REACT_APP_PUBLIC_URL}/pages/errors/error403`} element={<Error403 />} />
        <Route path={`${process.env.REACT_APP_PUBLIC_URL}/pages/errors/error404`} element={<Error404 />} />
        <Route path={`${process.env.REACT_APP_PUBLIC_URL}/pages/errors/error500`} element={<Error500 />} />
        <Route path={`${process.env.REACT_APP_PUBLIC_URL}/pages/errors/error503`} element={<Error503 />} />
        <Route path={`${process.env.REACT_APP_PUBLIC_URL}/callback`} render={() => <Callback />} />
      </Routes>
    </>
  );


};

export default MainRoutes;