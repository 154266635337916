// import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {
    const jwt_token = localStorage.getItem('token');
    const first_time_login = localStorage.getItem('first_time_login');

    // useEffect(() => {
    //     const abortController = new AbortController();
    //     const requestOptions = { method: 'GET', headers: authHeader() };
    //     fetch('/users', requestOptions).then(handleResponse)
    //     setAuthenticated(JSON.parse(localStorage.getItem("authenticated")))
      
    //     return function cleanup() {
    //         abortController.abort();
    //     }
    // }, []);

    return (
        jwt_token? <Outlet /> :first_time_login==0?<Navigate to={`${process.env.REACT_APP_PUBLIC_URL}/first`} />:<Navigate to={`${process.env.REACT_APP_PUBLIC_URL}/`} />);
}

export default PrivateRoute;

