import React, { useState, Fragment, useEffect } from 'react';
import API from '../../services/API';
// import Breadcrumb from '../common/breadcrumb';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Button } from 'reactstrap';
import SweetAlert from 'sweetalert2';
const Settings = (props) => {
  const [editState, setEdit] = useState(true);
  const [newEmail, setNewEmail] = useState();
  const [newEmailConfirm, setNewEmailConfirm] = useState();
  const [emailDisabled, setEmailDisabled] = useState();
  const [verificationCode, setVerificationCode] = useState('');
  const [newEmailSubmitDisabled, setNewEmailSubmitDisabled] = useState(true);
  const [verificationDisabled, setVerificationDisabled] = useState(true);
  const [getCodeButtonDisabled, setGetCodeButtonDisabled] = useState(true);
  const [newPassword, setNewPassword] = useState();
  const [newPasswordConfirm, setNewPasswordConfirm] = useState();
  const currentEmail = localStorage.getItem('email');
  const [state, setState] = useState({});
  const [selectedBank, setBank] = useState('');
  const [bankoptions, setBankOptions] = useState([]);

  var headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  };

  const handleNewEmailSubmit = async () => {
    API.post(
      '/employerUser/changeEmail',
      {
        email: currentEmail,
        new_email: newEmail,
        code: verificationCode,
      },
      { headers }
    )
      .then(async (response) => {
        if (response.data.response_code == 1000) {
          toast.success(`${response.data.message}`, { autoClose: 5000 });
          localStorage.removeItem('token');
          localStorage.removeItem('full_name');
          localStorage.removeItem('email');
          localStorage.removeItem('first_time_login');
          localStorage.removeItem('position');
          localStorage.removeItem('role');
          window.location.reload();
        } else {
          toast.error(`${response.data.message}`, { autoClose: 5000 });
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(`Error ${error.name}: ${error.code} ${error.message}`, {
            autoClose: 5000,
          });
        } else if (error.request) {
          toast.error('No response received from server', { autoClose: 5000 });
        } else {
          toast.error(`Error: ${error.message}`, { autoClose: 5000 });
        }
      });
  };

  const handleUpdateCompany = async () => {
    SweetAlert.fire({
      title: 'Are you sure?',
      text: 'Update Company Profile',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        API.put(
          '/companies/' + localStorage.getItem('company_id'),
          {
            company_name: state.company_name,
            ssm_no: state.ssm_no,
            email: state.email,
            e_number: state.e_number,
            c_number: state.c_number,
            socso_number: state.socso_number,
            epf_number: state.epf_number,
            contact_name: state.contact_name,
            contact_phone: state.contact_phone,
            address: state.address,
            organization_code: state.organization_code,
            bank_id: selectedBank.value,
            company_bank: selectedBank.label,
            company_bank_holder: state.company_bank_holder,
            company_bank_acc: state.company_bank_acc,
          },
          { headers }
        )
          .then(async (response) => {
            if (response.data.response_code == 1000) {
              toast.success(`${response.data.message}`, { autoClose: 5000 });
              // window.location.reload();
            } else {
              toast.error(`${response.data.message}`, { autoClose: 5000 });
            }
          })
          .catch((error) => {
            if (error.response) {
              toast.error(`Error ${error.name}: ${error.code} ${error.message}`, {
                autoClose: 5000,
              });
            } else if (error.request) {
              toast.error('No response received from server', { autoClose: 5000 });
            } else {
              toast.error(`Error: ${error.message}`, { autoClose: 5000 });
            }
          });
      }else{
        getCompanyInfo();
      }
    });
  };

  const getBankList = async (e) => {
    API.get('/banks', { headers }).then(async (response) => {
      const values = state.values;
      if (response.data.response_code == 1000) {
        const dropdownOptions = response.data.data.map((item) => ({
          value: item.id,
          label: item.bank_name,
        }));
        setBankOptions(dropdownOptions);
      } else if (response.data.response_code == 2000) {
        toast.error(`${response.data.message}`, { autoClose: 5000 });
      } else {
        toast.error(`${response.data.message}`, { autoClose: 5000 });
      }
    });
  };

  const handleSelectChange = (selectedOption) => {
    setBank(selectedOption);
  };

  const getCompanyInfo = async () => {
    API.get('/companies/' + localStorage.getItem('company_id'), { headers })
      .then(async (response) => {
        if (response.data.response_code == 1000) {
          setState(response.data.data);
          setBank({
            label: response.data.data.company_bank,
            value: response.data.data.company_id,
          });
        } else if (response.data.response_code == 2000) {
          toast.error(`${response.data.message}`, { autoClose: 5000 });
        } else {
          toast.error(`${response.data.message}`, { autoClose: 5000 });
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(`Error ${error.name}: ${error.code} ${error.message}`, {
            autoClose: 5000,
          });
        } else if (error.request) {
          toast.error('No response received from server', { autoClose: 5000 });
        } else {
          toast.error(`Error: ${error.message}`, { autoClose: 5000 });
        }
      });
  };

  const handleNewPasswordSubmit = async () => {
    const passwordPattern =/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+=[\]{};':"\\|,.<>/?]{8,}$/;
    
    if (typeof newPassword === 'undefined' || newPassword.length < 8) {
      toast.error('Password is too short', { autoClose: 2000 });
      return;
    }
    if (!passwordPattern.test(newPassword)) {
      toast.error(
        'Password should contain at least one uppercase letter, one lowercase letter and one digit',
        { autoClose: 2000 }
      );
      return;
    }
    if (newPasswordConfirm !== newPassword) {
      toast.error('Passwords do not match', { autoClose: 2000 });
      return;
    }
    SweetAlert.fire({
      title: 'Are you sure update password?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    }).then((result) => {
    API.post(
      '/employer/change-password',
      {
        email: currentEmail,
        password: newPassword,
      },
      { headers }
    )
      .then(async (response) => {
        if (response.data.response_code == 1000) {
          toast.success(`${response.data.message}`, { autoClose: 5000 });
        } else {
          toast.error(`${response.data.message}`, { autoClose: 5000 });
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(`Error ${error.name}: ${error.code} ${error.message}`, {
            autoClose: 5000,
          });
        } else if (error.request) {
          toast.error('No response received from server', { autoClose: 5000 });
        } else {
          toast.error(`Error: ${error.message}`, { autoClose: 5000 });
        }
      });
    });
  };

  const onEmailChange = (confirm, value) => {
    setGetCodeButtonDisabled(true);
    if (confirm) {
      setNewEmailConfirm(value);
    } else {
      setNewEmail(value);
    }
  };

  const handleGetCode = () => {
    var headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      token: localStorage.getItem('token'),
    };
    setVerificationDisabled(true);
    API.post('employerUser/getEmailCode', { email: newEmail }, { headers })
      .then(async (response) => {
        if (response.data.response_code == 1000) {
          toast.success('Code sent! Check your email', { autoClose: 2000 });
        }
        return;
      })
      .catch((error) => {
        if (error.response) {
          toast.error(`Error ${error.name}: ${error.code} ${error.message}`, {
            autoClose: 5000,
          });
        } else if (error.request) {
          toast.error('No response received from server', { autoClose: 5000 });
        } else {
          toast.error(`Error: ${error.message}`, { autoClose: 5000 });
        }
        return;
      });
  };

  const resetEntries = () => {
    document.querySelector('#newEmail-input').classList.remove('border-danger');
    document
      .querySelector('#newEmail-input')
      .classList.remove('border-success');
    document
      .querySelector('#newEmailConfirm-input')
      .classList.remove('border-danger');
    document
      .querySelector('#newEmailConfirm-input')
      .classList.remove('border-success');
    setNewEmail('');
    setNewEmailConfirm('');
    setVerificationCode('');
    setEmailDisabled(false);
    setVerificationDisabled(true);
    setGetCodeButtonDisabled(true);
    setNewEmailSubmitDisabled(true);
  };

  useEffect(() => {
    getBankList();
    getCompanyInfo();
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(newEmail)) {
      if(newEmail!='')
      // document.querySelector('#newEmail-input').classList.add('border-danger');
      return;
    }
    if (newEmail === currentEmail) {
      document.querySelector('#newEmail-input').classList.add('border-danger');
      return;
    }
    document.querySelector('#newEmail-input').classList.remove('border-danger');
    document.querySelector('#newEmail-input').classList.add('border-success');
    if (newEmail != newEmailConfirm) {
      document
        .querySelector('#newEmailConfirm-input')
        .classList.add('border-danger');
      return;
    }

    document
      .querySelector('#newEmailConfirm-input')
      .classList.remove('border-danger');
    document
      .querySelector('#newEmailConfirm-input')
      .classList.add('border-success');
    setEmailDisabled(true);
    setVerificationDisabled(false);
    setGetCodeButtonDisabled(false);
    if (verificationCode) {
      if (verificationCode.length == 6) {
        setNewEmailSubmitDisabled(false);
      } else {
        setNewEmailSubmitDisabled(true);
      }
    }
  }, [newEmail, newEmailConfirm, currentEmail, verificationCode]);

  const onChange = (field, event) => {
    let fieldValue = event.target.value;
    setState((state) => ({
      ...state,
      [field]: fieldValue,
    }));
  };

  return (
    <Fragment>
      {/*<Breadcrumb parent="Dashboard" title="Settings" />*/}
      <div className="container-fluid">
        <div className="row">
          <div className="card">
            <div className="card-header row" style={{ padding: '20px' }}>
              <h5 className="col-10">Company Details</h5>
              
            </div>
            <form className="form theme-form">
              <div className="card-body">
                <div className="mb-3 row">
                  <label
                    htmlFor="company_name"
                    className="col-sm-2 col-form-label"
                    style={{ textAlign: 'right' }}
                  >
                    Company Name
                  </label>
                  <div className="col-sm-10">
                    <input
                      disabled={editState}
                      className="form-control"
                      id="company_name"
                      type="text"
                      value={state.company_name}
                      onChange={(e) => onChange('company_name', e)}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    htmlFor="ssm_no"
                    className="col-sm-2 col-form-label"
                    style={{ textAlign: 'right' }}
                  >
                    Registration Number
                  </label>
                  <div className="col-sm-10">
                    <input
                      disabled={editState}
                      className="form-control"
                      id="ssm_no"
                      type="text"
                      value={state.ssm_no}
                      onChange={(e) => onChange('ssm_no', e)}
                    />
                  </div>
                </div>
                <div className=" mb-3 row">
                  <label
                    htmlFor="email"
                    className="col-sm-2 col-form-label"
                    style={{ textAlign: 'right' }}
                  >
                    Company Email
                  </label>
                  <div className="col-sm-10">
                    <input
                      disabled={editState}
                      className="form-control"
                      id="email"
                      type="email"
                      value={state.email}
                      onChange={(e) => onChange('email', e)}
                    />
                  </div>
                </div>
                <div className="row">
                  <label
                    htmlFor="contact_name"
                    className="col-sm-2 col-form-label"
                    style={{ textAlign: 'right' }}
                  >
                    Contact Person
                  </label>
                  <div className="col-sm-3">
                    <input
                      disabled={editState}
                      className="form-control"
                      id="contact_name"
                      type="text"
                      value={state.contact_name}
                      onChange={(e) => onChange('contact_name', e)}
                    />
                  </div>
                  <label
                    htmlFor="contact_phone"
                    className="col-sm-2 col-form-label"
                    style={{ textAlign: 'right' }}
                  >
                    Contact Number
                  </label>
                  <div className="col-sm-3">
                    <input
                      disabled={editState}
                      className="form-control"
                      id="contact_phone"
                      type="text"
                      value={state.contact_phone}
                      onChange={(e) => onChange('contact_phone', e)}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="card-body">
                <div className="mb-3 row">
                  <label
                    htmlFor="company_bank"
                    className="col-sm-2 col-form-label"
                    style={{ textAlign: 'right' }}
                  >
                    Bank
                  </label>
                  <div className="col-sm-10">
                    <Select
                      isDisabled={editState}
                      id="bank_name"
                      value={selectedBank}
                      onChange={handleSelectChange}
                      options={bankoptions}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    htmlFor="company_bank_holder"
                    className="col-sm-2 col-form-label"
                    style={{ textAlign: 'right' }}
                  >
                    Account Holder Name
                  </label>
                  <div className="col-sm-2">
                    <input
                      disabled={editState}
                      className="form-control"
                      id="company_bank_holder"
                      type="text"
                      value={state.company_bank_holder}
                      onChange={(e) => onChange('company_bank_holder', e)}
                    />
                  </div>
                  <label
                    htmlFor="company_bank_acc"
                    className="col-sm-2 col-form-label"
                    style={{ textAlign: 'right' }}
                  >
                    Bank Account
                  </label>
                  <div className="col-sm-2">
                    <input
                      disabled={editState}
                      className="form-control"
                      id="company_bank_acc"
                      type="text"
                      value={state.company_bank_acc}
                      onChange={(e) => onChange('company_bank_acc', e)}
                    />
                  </div>
                  <label
                    htmlFor="organization_code"
                    className="col-sm-2 col-form-label"
                    style={{ textAlign: 'right' }}
                  >
                    Organization Code
                  </label>
                  <div className="col-sm-2">
                    <input
                      disabled={editState}
                      className="form-control"
                      id="organization_code"
                      type="text"
                      value={state.organization_code}
                      onChange={(e) => onChange('organization_code', e)}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    disabled={editState}
                    htmlFor="e_number"
                    className="col-sm-2 col-form-label"
                    style={{ textAlign: 'right' }}
                  >
                    E Number
                  </label>
                  <div className="col-sm-10">
                    <input
                      disabled={editState}
                      className="form-control"
                      id="e_number"
                      type="text"
                      value={state.e_number}
                      onChange={(e) => onChange('e_number', e)}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    disabled={editState}
                    htmlFor="c_number"
                    className="col-sm-2 col-form-label"
                    style={{ textAlign: 'right' }}
                  >
                    C Number
                  </label>
                  <div className="col-sm-10">
                    <input
                      disabled={editState}
                      className="form-control"
                      id="c_number"
                      type="text"
                      value={state.c_number}
                      onChange={(e) => onChange('c_number', e)}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    disabled={editState}
                    htmlFor="epf_number"
                    className="col-sm-2 col-form-label"
                    style={{ textAlign: 'right' }}
                  >
                    EPF Number
                  </label>
                  <div className="col-sm-10">
                    <input
                      disabled={editState}
                      className="form-control"
                      id="epf_number"
                      type="text"
                      value={state.epf_number}
                      onChange={(e) => onChange('epf_number', e)}
                    />
                  </div>
                </div>
                <div className="row">
                  <label
                    htmlFor="socso_number"
                    className="col-sm-2 col-form-label"
                    style={{ textAlign: 'right' }}
                  >
                    Socso Number
                  </label>
                  <div className="col-sm-10">
                    <input
                      disabled={editState}
                      className="form-control"
                      id="socso_number"
                      type="text"
                      value={state.socso_number}
                      onChange={(e) => onChange('socso_number', e)}
                    />
                  </div>
                </div>
              </div>
              <div className="card-header" style={{ padding: '10px' }}>
                <h5>Scheme Calculation *Not Changeable</h5>
              </div>
              <div className="card-body">
                <div className="mb-3 row">
                  <label
                    htmlFor="pay_day"
                    className="col-sm-2 col-form-label"
                    style={{ textAlign: 'right' }}
                  >
                    Salary Pay Day
                  </label>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      id="pay_day"
                      type="text"
                      value={state.pay_day}
                      disabled
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    htmlFor="pay_start_cycle"
                    className="col-sm-2 col-form-label"
                    style={{ textAlign: 'right' }}
                  >
                    First Day of withdrawal
                  </label>
                  <div className="col-sm-4">
                    <input
                      className="form-control"
                      id="pay_start_cycle"
                      type="text"
                      value={state.pay_start_cycle}
                      disabled
                    />
                  </div>
                  <label
                    htmlFor="pay_end_cycle"
                    className="col-sm-2 col-form-label"
                    style={{ textAlign: 'right' }}
                  >
                    Last Day of withdrawal
                  </label>
                  <div className="col-sm-4">
                    <input
                      className="form-control"
                      id="pay_end_cycle"
                      type="text"
                      value={state.pay_end_cycle}
                      disabled
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    htmlFor="min_withdraw_limit_per_txn"
                    className="col-sm-2 col-form-label"
                    style={{ textAlign: 'right' }}
                  >
                    Minimum Withdrawal per transaction
                  </label>
                  <div className="col-sm-4">
                    <input
                      className="form-control"
                      id="min_withdraw_limit_per_txn"
                      type="text"
                      value={state.min_withdraw_limit_per_txn}
                      disabled
                    />
                  </div>
                  <label
                    htmlFor="max_widthraw_limit_per_txn"
                    className="col-sm-2 col-form-label"
                    style={{ textAlign: 'right' }}
                  >
                    Maximum Withdrawal per transaction
                  </label>
                  <div className="col-sm-4">
                    <input
                      className="form-control"
                      id="max_widthraw_limit_per_txn"
                      type="text"
                      value={state.max_widthraw_limit_per_txn}
                      disabled
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    htmlFor="company_withdraw_limit"
                    className="col-sm-2 col-form-label"
                    style={{ textAlign: 'right' }}
                  >
                    Company’s Max Disbursement (Ongoing)
                  </label>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      id="company_withdraw_limit"
                      type="text"
                      value={state.company_withdraw_limit}
                      disabled
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    htmlFor="employee_withdraw_limit"
                    className="col-sm-2 col-form-label"
                    style={{ textAlign: 'right' }}
                  >
                    Monthly Employee Disbursement Limit
                  </label>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      id="employee_withdraw_limit"
                      type="text"
                      value={state.employee_withdraw_limit}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="card-footer text-end">
                {editState == true ?
                  <Button color="primary" onClick={() => setEdit(!editState)}>Edit</Button>
                  :
                  <Button color="primary" onClick={() => {setEdit(!editState); handleUpdateCompany();}}>Update</Button>
                }
              </div>
            </form>
          </div>

          <div className="card">
            <div className="card-header">
              <h4 htmlFor="exampleFormControlInput1">User Account</h4>
            </div>
            <form className="form theme-form">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <label htmlFor="exampleFormControlInput1">
                      Company Name
                    </label>
                    <input
                      className="form-control"
                      id="exampleFormControlInput1"
                      type="email"
                      placeholder={currentEmail}
                      disabled
                      style={{ width: 360 }}
                    />
                  </div>
                </div>
                <div
                  className="row"
                  style={{ paddingTop: 22, paddingBottom: 15 }}
                >
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlInput1">
                        New Email Address
                      </label>
                      <input
                        onChange={(e) => onEmailChange(false, e.target.value)}
                        className="form-control"
                        id="newEmail-input"
                        type="email"
                        placeholder="name@example.com"
                        disabled={emailDisabled}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlInput1">
                        Confirm New Email Address
                      </label>
                      <input
                        onChange={(e) => onEmailChange(true, e.target.value)}
                        className="form-control"
                        id="newEmailConfirm-input"
                        type="email"
                        placeholder="name@example.com"
                        disabled={emailDisabled}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlInput1">
                        Verification code
                      </label>
                      <input
                        onChange={(e) => setVerificationCode(e.target.value)}
                        className="form-control"
                        id="code-input"
                        type="text"
                        disabled={verificationDisabled}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer text-end">
                <button
                  className="btn btn-primary me-2"
                  onClick={() => handleGetCode()}
                  disabled={getCodeButtonDisabled}
                  type="button"
                >
                  Get Code
                </button>
                <button
                  className="btn btn-primary me-2"
                  onClick={() => handleNewEmailSubmit()}
                  disabled={newEmailSubmitDisabled}
                  type="button"
                >
                  Update
                </button>
                <input
                  className="btn btn-light"
                  type="reset"
                  onClick={() => resetEntries()}
                  defaultValue="Cancel"
                />
              </div>
            </form>
          </div>
          <div className="card">
            <div className="card-header">
              <h4 htmlFor="exampleFormControlInput1">Password</h4>
            </div>
            <form className="form theme-form">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword">
                        Update Password
                      </label>
                      <input
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="form-control"
                        id="exampleInputPassword2"
                        type="password"
                        placeholder="Password"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword2">
                        Confirm Password
                      </label>
                      <input
                        onChange={(e) => setNewPasswordConfirm(e.target.value)}
                        className="form-control"
                        id="exampleInputPassword2"
                        type="password"
                        placeholder="Password"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div id="too-short" className="text">
                    - At least 8 characters
                  </div>
                  <div id="no-uppercase" className="text">
                    - At least one uppercase letter
                  </div>
                  <div id="no-lowercase" className="text">
                    - At least one lowercase letter
                  </div>
                  <div id="no-digits" className="text">
                    - At least one digit
                  </div>
                </div>
                <div className="card-footer text-end">
                  <button
                    className="btn btn-primary me-2"
                    onClick={() => handleNewPasswordSubmit()}
                    type="button"
                  >
                    Update
                  </button>
                  <input
                    className="btn btn-light"
                    type="reset"
                    defaultValue="Cancel"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Settings;
