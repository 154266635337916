import React, { useState } from 'react';
import { LOGIN, Password } from '../constant';
import { useNavigate } from 'react-router-dom';
import API from '../services/API';
// import { routes } from '../routes/layouts-routes';
import { toastify } from '../components/toastify';

const LoginPage = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    email: '',
    password: '',
  });

  const handleSubmit = async () => {
    API.post('/employer/login', {
      work_email: state.email,
      password: state.password,
    })
      .then(async (response) => {
        if (response.data.response_code == 1000) {
          localStorage.setItem('token', response.data.data.token);
          localStorage.setItem('full_name', response.data.data.full_name);
          localStorage.setItem('email', response.data.data.work_email);
          localStorage.setItem('role', response.data.data.user_role);
          localStorage.setItem('position', response.data.data.position);
          localStorage.setItem(
            'first_time_login',
            response.data.data.first_time_login
          );
          localStorage.setItem('company_id', response.data.data.company_id);
          if (response.data.data.first_time_login != '1') {
            if (response.data.data.status == 'Locked')
              toastify.error('Account Locked . Please Contact Onde!', {
                autoClose: 5000,
              });
            else navigate(`${process.env.REACT_APP_PUBLIC_URL}/first`);
          } else {
            navigate(`${process.env.REACT_APP_PUBLIC_URL}/dashboard`);
          }
        } else if (response.data.response_code == 2000) {
          toastify.error(`${response.data.message}`, { autoClose: 5000 });
        } else {
          toastify.error(`${response.data.message}`, { autoClose: 5000 });
        }
      })
      .catch(function (error) {
        if (error.response) {
          toastify.error(`${error.response.data.message}`, { autoClose: 5000 });
        } else {
          toastify.error(`${error.message}`, { autoClose: 5000 });
        }
      });
  };

  const onChange = (field, event) => {
    let fieldValue = event.target.value;
    setState((state) => ({
      ...state,
      [field]: fieldValue,
    }));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid p-0">
          {/* <!-- login page start--> */}
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center">
                      <img
                        src={'https://imgur.com/3oTOSqH.png'}
                        alt=""
                        height={150}
                      />
                    </div>
                    <div className="card mt-4">
                      <div className="card-body">
                        <div className="text-center">
                          <h4>{LOGIN}</h4>
                          <h6>{'Enter your Username and Password'} </h6>
                        </div>
                        <form className="theme-form">
                          <div className="form-group">
                            <label className="col-form-label pt-0">
                              Work Email
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              value={state.email}
                              onChange={(e) => onChange('email', e)}
                            />
                          </div>
                          <div className="form-group">
                            <label className="col-form-label">{Password}</label>
                            <input
                              className="form-control"
                              type="password"
                              value={state.password}
                              onChange={(e) => onChange('password', e)}
                              onKeyDown={handleKeyDown}
                            />
                          </div>
                          <div className="form-group form-row mt-3 mb-0 d-grid">
                            <button
                              className="btn"
                              style={{
                                backgroundColor: '#168A53',
                                color: 'white',
                                fontWeight: 'bold',
                              }}
                              type="button"
                              onClick={() => handleSubmit()}
                            >
                              {'LOGIN'}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- login page end--> */}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
