import React, { useState, Fragment, useEffect } from 'react';
import DataTable from 'react-data-table-component';
// import Breadcrumb from '../common/breadcrumb';
import CountUp from 'react-countup';
import { toast } from 'react-toastify';
import API from '../../services/API';
import { useNavigate } from 'react-router-dom';

const Homepage = (props) => {
  const navigate = useNavigate();
  const history = useNavigate();
  const [state, setState] = useState({
    // total_amount: 0,
    // average_amount: 0,
    advance_limit: 0,
    new_total_amount: 0,
    employee_advance: 0,
    user_accessed: 0,
    pending_action: 0,
  });
  const [topEmployee, setTopEmployee] = useState([]);
  const [columns, setColumns] = useState([
    {
      name: 'Name',
      selector: (row) => row.full_name,
      sortable: true,
      center: true,
    },
    {
      name: 'Number of transactions',
      selector: (row) => row.total_transaction,
      sortable: true,
      center: true,
    },
    {
      name: 'Amount',
      selector: (row) => row.total_amount,
      sortable: true,
      center: false,
      minWidth: '300px',
    },
  ]);

  useEffect(() => {
    const first_time_login = localStorage.getItem('first_time_login');
    if (first_time_login == 1) {
      var headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      };
      API.get('/dashboard/monthly-summary', { headers })
        .then((response) => {
          setState(response.data.data);
          //history.push(`${process.env.REACT_APP_PUBLIC_URL}/dashboard`);
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.data.message == 'Invalid Token') {
              history(`${process.env.REACT_APP_PUBLIC_URL}/`);
            }
            toast.error(`${error.response.data.message}`, { autoClose: 5000 });
          } else {
            toast.error(`${error.message}`, { autoClose: 5000 });
          }
        });
      API.get('/dashboard/top-five-spender', { headers })
        .then((response) => {
          if (response.data.response_code == 1000) {
            setTopEmployee(response.data.data);
          }
        })
        .catch(function (error) {
          if (error.response) {
            toast.error(`${error.response.data.message}`, { autoClose: 5000 });
          } else {
            toast.error(`${error.message}`, { autoClose: 5000 });
          }
        });
    } else {
      navigate(`${process.env.REACT_APP_PUBLIC_URL}/first`);
    }
  }, []);
  const advancedTextColor = () => {
    let textColor = 'text-green';
    if (state.total_amount && state.advance_limit) {
      let percentage = (state.total_amount / state.advance_limit) * 100;
      if (percentage >= 100) {
        textColor = 'text-red';
      } else if (percentage >= 90) {
        textColor = 'text-amber';
      }
    }
    return textColor;
  };
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 xl-100">
            <div className="row">
              <div className="col-xl-4 col-lg-12">
                <DashboardCard
                  mainComponent={
                    <>
                      <span className={advancedTextColor()}>
                        RM &nbsp;
                        {state.total_amount}
                      </span>
                      &nbsp;
                      <span className="text-gray">
                        / RM &nbsp;
                        {state.advance_limit}
                        &nbsp; limit
                      </span>
                    </>
                  }
                  subComponent={
                    <span className="text-green font-weight-bold">
                      has been advanced this month
                    </span>
                  }
                />
              </div>
              <div className="col-xl-4 col-lg-12">
                <DashboardCard
                  mainComponent={
                    <div className="">
                      {state.employee_advance}
                    </div>
                  }
                  subComponent={
                    <span className="text-green font-weight-bold">
                      Employees advanced this month
                    </span>
                  }
                />
              </div>
              <div className="col-xl-4 col-lg-12">
                <DashboardCard
                  theme="dark"
                  mainComponent={
                    <div className="">
                      {/* {state.pending_action} */}0
                    </div>
                  }
                  subComponent={
                    <span className="text-blue font-weight-bold">
                      Actions Pending
                    </span>
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-xl-12 xl-50">
            <div
              className="card rounded-25"
              data-intro="This is Our Topper List"
            >
              <div className="card-header rounded-25 text-green">
                <h5>Latest Transactions (this month)</h5>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={topEmployee}
                    striped={true}
                    center={true}
                    persistTableHead
                  />
                </div>
                <div className="pt-2">
                  <div className="d-flex justify-content-center">
                    <div
                      className="text-gray"
                      role="button"
                      onClick={() => navigate('/transactions')}
                    >
                      Show More
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Homepage;

const DashboardCard = (props) => {
  //null,"dark"
  const [cardClassName, setCardClassName] = useState(props.theme || '');
  return (
    <div className={'card rounded-25 text-center ' + cardClassName}>
      <div className="card-body">
        <div className="">
          <div className="mb-0 mainComponent">{props.mainComponent}</div>
          <div className="subComponent pt-3">{props.subComponent}</div>
        </div>
      </div>
    </div>
  );
};
