import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';

const UserPanel = () => {
    const url = '';
    const full_name=localStorage.getItem('full_name')
    const position=localStorage.getItem('position')
    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <div>
                    {/* <img className="img-60 rounded-circle lazyloaded blur-up" src={url ? url : man} alt="#" /> */}
                    <div className="profile-edit">
                        <Link to={`${process.env.REACT_APP_PUBLIC_URL}/users/userEdit`}>
                            <Edit />
                        </Link>
                    </div>
                </div>
                <h6 className="mt-3 f-14">{full_name}</h6>
                <p>{position}</p>
            </div>
        </Fragment>
    );
};

export default UserPanel;