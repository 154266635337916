import React, { Fragment } from 'react';
import { Home } from 'react-feather';
import { Link, useLocation } from 'react-router-dom';
import Bookmark from './bookmark';
import { MENUITEMS } from './sidebar-component/menu';
const Breadcrumb = (props) => {
  const breadcrumb = props;
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((path) => path !== '');
  const findMenuItemByPath = (menuItems, path) => {
    for (let item of menuItems) {
      if (item.path === path) {
        return item;
      } else if (item.children && item.children.length > 0) {
        const foundItem = findMenuItemByPath(item.children, path);
        if (foundItem) {
          return foundItem;
        }
      }
    }
    return null;
  };

  const findMenuItemTitle = (path) => {
    const menuItem = findMenuItemByPath(MENUITEMS, `/${path}`);
    return menuItem ? menuItem.title : 'Unknown Page';
  };
  const title = findMenuItemTitle(pathnames);
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="page-header">
          <div className="row">
            <div className="col">
              <div className="page-header-left">
                <h3>{title}</h3>
                {/*<ol className="breadcrumb pull-right">
                                    <li className="breadcrumb-item">
                                        <Link to={`${process.env.REACT_APP_PUBLIC_URL}/dashboard/default`}>
                                            <Home />
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item">{breadcrumb.parent}</li>
                                    <li className="breadcrumb-item active">{breadcrumb.title}</li>
                                </ol>*/}
              </div>
            </div>
            {/* <!-- Bookmark Start--> */}
            {/* <!-- Bookmark Ends--> */}
          </div>
          <div className="row">
            <div className="col">
              <h4>Last updated: {breadcrumb.lastUpdatedDateTime}</h4>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Breadcrumb;
