import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer, toast/*, ToastContent, ToastOptions*/ } from 'react-toastify';

let hasToastContainer = false;

const setupToastContainer = () => {
	if (!hasToastContainer) {
		hasToastContainer = true;
		ReactDOM.render(
			<ToastContainer position={toast.POSITION.TOP_RIGHT} />,
			document.getElementById('root-toastify')
		);
	}
};

export const show = (message, options = {}) => {
	setupToastContainer();
	toast(message, options);
};

export const success = (message, options = {}) => {
	setupToastContainer();
	toast.success(message, options);
};

export const error = (message, options = {}) => {
	setupToastContainer();
	toast.error(message, options);
};

export const toastify = {
	show: (message, options) => show(message, options),
	success: (message, options) => success(message, options),
	error: (message, options) => error(message, options),
};

