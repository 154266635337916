import React from "react";
// import "../../assets/scss/loading.css";

export default function LoadingSpinner() {
  return (
    <div className="overlay">
    <span className="overlay-text">
    <div className="spinner-container">
      <div className="loading-spinner">
      </div>
    </div>
    </span>
    </div>
  );
}