import React, { Fragment, useState, useEffect } from 'react';
// import Breadcrumb from '../common/breadcrumb';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import DatePicker from 'react-datepicker';
import seven from '../../assets/images/user/7.jpg';

import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { UpdateProfile, EmailAddress } from '../../constant';
import { ToastContainer, toast } from 'react-toastify';
import API from '../../services/API';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import SweetAlert from 'sweetalert2';
import Select from 'react-select';

const EmployeeProfile = () => {
  const [data, setData] = useState([]);
  const history = useNavigate();
  const multiple = false;
  const pathname = window.location.pathname;

  const pathId = pathname.substring(pathname.lastIndexOf('/') + 1);
  const id = pathId ? pathId : 1;
  const [state, setState] = useState({});
  const [editState, setEdit] = useState(true);
  const [userStatus, setUserStatus] = useState(1);
  const [salutation, setSalutation] = useState('Mr');
  const [radioButton, setRadioButton] = useState(1);
  const [modal, setModal] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [workStatus, setWorkStatus] = useState(1);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const toggle2 = () => setDropdownOpen2((prevState) => !prevState);

  const [dropdownOpen3, setDropdownOpen3] = useState(false);
  const toggle3 = () => setDropdownOpen3((prevState) => !prevState);

  const [selectedBank, setBank] = useState('');
  const [bankoptions, setBankOptions] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  var date = new Date();
  const [format_start_date, setFormatStartDate] = useState(
    String(
      date.getFullYear() +
        '-' +
        (date.getMonth() < 12 ? date.getMonth() + 1 : 1) +
        '-' +
        1
    )
  );
  const [format_probation_end_date, setFormatProbationEndDate] = useState(
    String(
      date.getFullYear() +
        '-' +
        (date.getMonth() < 12 ? date.getMonth() + 1 : 1) +
        '-' +
        1
    )
  );
  const toggleModal = () => {
    setModal(!modal);
  };
  var headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  };

  const getBankList = async (e) => {
    var headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    };
    API.get('/banks', { headers }).then(async (response) => {
      const values = state.values;
      if (response.data.response_code == 1000) {
        const dropdownOptions = response.data.data.map((item) => ({
          value: item.id,
          label: item.bank_name,
        }));
        setBankOptions(dropdownOptions);
      } else if (response.data.response_code == 2000) {
        toast.error(`${response.data.message}`, { autoClose: 5000 });
      } else {
        toast.error(`${response.data.message}`, { autoClose: 5000 });
      }
    });
  };

  const handleSelectChange = (selectedOption) => {
    setBank(selectedOption);
  };

  useEffect(() => {
    API.get('/users/' + id, { headers })
      .then(async (response) => {
        if (response.data.response_code == 1000) {
          setState(response.data.data);

          setWorkStatus(response.data.data.status);
          setUserStatus(response.data.data.status);
          setBank({
            label: response.data.data.bank_account_name,
            value: response.data.data.bank_id,
          });
          setSalutation(response.data.data.salutation);
          if (response.data.data.identification_number !== null) {
            setRadioButton(1);
          } else {
            setRadioButton(2);
          }
          setStartDate(new Date(response.data.data.start_date));
          setEndDate(new Date(response.data.data.probation_end_date));
          //toast.success(`${response.data.message}`, { autoClose: 5000 })
          //history.push(`${process.env.REACT_APP_PUBLIC_URL}/dashboard`);
        } else if (response.data.response_code == 2000) {
          toast.error(`${response.data.message}`, { autoClose: 5000 });
        } else {
          toast.error(`${response.data.message}`, { autoClose: 5000 });
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(`Error ${error.name}: ${error.code} ${error.message}`, {
            autoClose: 5000,
          });
        } else if (error.request) {
          toast.error('No response received from server', { autoClose: 5000 });
        } else {
          toast.error(`Error: ${error.message}`, { autoClose: 5000 });
        }
      });
    getBankList();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!state.full_name) {
      document.getElementById('full_name').classList.add('border-danger');
      return;
    }
    document.getElementById('full_name').classList.remove('border-danger');
    if (!/^\+60\d{1}-?\d{3,4}\s?\d{4}$/.test('+' + state.contact_no)) {
      document.getElementById('contact_no').classList.add('border-danger');
      return;
    }
    document.getElementById('contact_no').classList.remove('border-danger');
    if (state.salary < 10) {
      document.getElementById('salary').classList.add('border-danger');
      return;
    }
    document.getElementById('salary').classList.remove('border-danger');
    if (!state.identification_number && !state.passport_number) {
      if (!state.identification_number) {
        document
          .getElementById('identification_number')
          .classList.add('border-danger');
      }
      // if (!emailPattern.test(state.passport_number)) {
      //     document.getElementById('passport_number').classList.add('border-danger')
      // }
      // return;
    }
    document
      .getElementById('identification_number')
      .classList.remove('border-danger');
    // document.getElementById('passport_number').classList.remove('border-danger')
    // if (!state.bank_account_name) {
    // document.getElementById('bank_name').classList.add('border-danger')
    // return;
    // }
    // document.getElementById('bank_name').classList.remove('border-danger')
    if (!state.bank_account_no) {
      document.getElementById('bank_account_no').classList.add('border-danger');
      return;
    }
    document
      .getElementById('bank_account_no')
      .classList.remove('border-danger');

    var headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Bearer' + localStorage.getItem('token'),
    };
    SweetAlert.fire({
      title: 'Are you sure?',
      text: 'Once this profile has been updated there is no changes allowed',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        API.post(
          '/users/' + state.id,
          {
            id: state.id,
            employee_code: state.employee_code,
            role: state.role !== undefined ? state.role : 0,
            position: state.position,
            salutation: salutation,
            full_name: state.full_name,
            identification_number: state.identification_number,
            contact_no: state.contact_no,
            email: state.email,
            start_date: startDate,
            confirmed: workStatus,
            salary: state.salary,
            bank_account_no: state.bank_account_no,
            bank_account_name: selectedBank.label,
            bank_id: selectedBank.value,
            office: state.office,
          },
          { headers }
        ).then(async (response) => {
          if (response.data.response_code == 1000) {
            toggleModal();
            SweetAlert.fire('Updated!', 'Profile has been updated', 'success');
            //toast.success(`${response.data.message}`, { autoClose: 5000 })
            history(`${process.env.REACT_APP_PUBLIC_URL}/employee`);
          } else if (response.data.response_code == 2000) {
            toast.error(`${response.data.message}`, { autoClose: 5000 });
          } else {
            toast.error(`${response.data.message}`, { autoClose: 5000 });
          }
        });
      } else {
        // SweetAlert.fire(
        //     'Something wrong with updating profile, please try again'
        // )
      }
    });
  };
  const updateUser = async () => {
    //event.preventDefault();
    var headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    };
    API.post(
      '/users/update-status',
      {
        account_status: userStatus,
        user_id: state.id,
        reason: state.reason,
      },
      { headers }
    ).then(async (response) => {
      const values = state.values;
      if (response.data.response_code == 1000) {
        //toggle2()
        toast.success(`${response.data.message}`, { autoClose: 5000 });
        // window.location.reload();
        history(`${process.env.REACT_APP_PUBLIC_URL}/employee`);
      } else if (response.data.response_code == 2000) {
        toast.error(`${response.data.message}`, { autoClose: 5000 });
      } else {
        toast.error(`${response.data.message}`, { autoClose: 5000 });
      }
    });
  };
  const handleChange = (date) => {
    var the_formatted_date =
      date.getFullYear() +
      '-' +
      (date.getMonth() < 12 ? date.getMonth() + 1 : 1) +
      '-' +
      date.getDate();
    setFormatStartDate(String(the_formatted_date));
    setStartDate(date);
  };
  const handleChangeProbation = (date) => {
    var the_formatted_date =
      date.getFullYear() +
      '-' +
      (date.getMonth() < 12 ? date.getMonth() + 1 : 1) +
      '-' +
      date.getDate();
    setFormatProbationEndDate(String(the_formatted_date));
    setEndDate(date);
  };
  const onChange = (field, event) => {
    let fieldValue = event.target.value;
    setState((state) => ({
      ...state,
      [field]: fieldValue,
    }));
  };
  return (
    <Fragment>
      {/*<Breadcrumb title="Profile" parent="Employee Profile" />*/}
      <ToastContainer />
      <div className="container-fluid">
        <Modal isOpen={modal} toggle={toggleModal} centered={true}>
          <ModalHeader toggle={toggleModal}>Update User Status</ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label htmlFor="status">Account Status</label>
              <Dropdown className="" isOpen={dropdownOpen3} toggle={toggle3}>
                <DropdownToggle className="border" color="default" caret>
                  {userStatus}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => setUserStatus('Suspended')}>
                    Suspended
                  </DropdownItem>
                  <DropdownItem onClick={() => setUserStatus('Inactive')}>
                    Inactive
                  </DropdownItem>
                  <DropdownItem onClick={() => setUserStatus('Archived')}>
                    Delete
                  </DropdownItem>
                  <DropdownItem onClick={() => setUserStatus('Active')}>
                    Active
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            <div className="form-group">
              <label htmlFor="reason">Reason</label>
              <input
                className="form-control"
                id="reasoning"
                type="text"
                value={state.reason}
                onChange={(e) => onChange('reason', e)}
                placeholder="Enter reason"
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => updateUser()}>
              Update
            </Button>
            <Button color="danger" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <div className="edit-profile">
          <div className="row">
            <div className="col-lg-4">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title mb-0">Profile</h4>
                  <span className="pull-right">
                    <Button color="primary" onClick={toggleModal}>
                      Update Account Status
                    </Button>
                  </span>
                  <div className="card-options">
                    <a
                      className="card-options-collapse"
                      href="javascript"
                      data-toggle="card-collapse"
                    >
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a
                      className="card-options-remove"
                      href="javascript"
                      data-toggle="card-remove"
                    >
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </div>

                <div className="card-body">
                  <form>
                    <div className="row mb-2">
                      <div className="col-auto">
                        <img
                          className="img-70 rounded-circle"
                          alt=""
                          src={seven}
                        />
                      </div>
                      <div className="col">
                        <h3 className="mb-1">{state.full_name}</h3>
                        <p className="mb-4">{state.position}</p>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="form-group">
                        <div className="input-group">
                          <div className="">
                            <label htmlFor="salutation">Salutation</label>
                            <Dropdown
                              className="border"
                              isOpen={dropdownOpen}
                              toggle={toggle}
                            >
                              <DropdownToggle
                                disabled={editState}
                                className="form-control border"
                                color="default"
                                caret
                              >
                                {salutation}
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  onClick={() => setSalutation('Mr')}
                                >
                                  Mr
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => setSalutation('Mrs')}
                                >
                                  Mrs
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => setSalutation('Ms')}
                                >
                                  Ms
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => setSalutation('Dr')}
                                >
                                  Dr
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => setSalutation('Dato')}
                                >
                                  Dato
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => setSalutation('Datuk')}
                                >
                                  Datuk
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => setSalutation('Tan Sri')}
                                >
                                  Tan Sri
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label htmlFor="full_name">
                                Full Name as per NRIC:
                                <span className="font-danger">{'*'}</span>
                              </label>
                              <input
                                disabled={editState}
                                className="form-control"
                                id="full_name"
                                type="text"
                                value={state.full_name}
                                onChange={(e) => onChange('full_name', e)}
                                placeholder="Enter your full name"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row form-group m-t-15 m-b-15 custom-radio-ml">
                      <div className="col-6 radio radio-primary">
                        <input
                          disabled={editState}
                          id="radio1"
                          type="radio"
                          name="radio1"
                          value={1}
                          onChange={(e) => setRadioButton(e.target.value)}
                          defaultChecked
                        />
                        <label disabled={editState} htmlFor="radio1">
                          MyKad
                        </label>
                      </div>
                      <div className="col-6 radio radio-primary">
                        <input
                          id="radio2"
                          type="radio"
                          name="radio1"
                          value={2}
                          onChange={(e) => setRadioButton(e.target.value)}
                        />
                        <label disabled={editState} htmlFor="radio2">
                          Passport Number
                        </label>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12">
                        {radioButton == 1 ? (
                          <div className="form-group">
                            <label htmlFor="identification_number">
                              Identification Number:
                              <span className="font-danger">{'*'}</span>
                            </label>
                            <input
                              disabled={editState}
                              className="form-control"
                              id="identification_number"
                              type="text"
                              value={state.identification_number}
                              onChange={(e) =>
                                onChange('identification_number', e)
                              }
                              placeholder="Identification number"
                            />
                          </div>
                        ) : null}

                        {radioButton == 2 ? (
                          <div className="form-group">
                            <label htmlFor="passport_number">
                              Passport Number:
                            </label>
                            <input
                              disabled={editState}
                              className="form-control"
                              id="passport_number"
                              type="text"
                              value={state.passport_number}
                              onChange={(e) => onChange('passport_number', e)}
                              placeholder="Passport number"
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <label className="form-label">Employee ID</label>
                      <input
                        disabled={editState}
                        className="form-control"
                        value={state.employee_code}
                        onChange={(e) => onChange('employee_code', e)}
                        placeholder="employee id"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <form className="card">
                <div className="card-header">
                  <h4 className="card-title mb-0">Profile</h4>
                  <div className="card-options">
                    <a
                      className="card-options-collapse"
                      href=""
                      data-toggle="card-collapse"
                    >
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a
                      className="card-options-remove"
                      href="javascript"
                      data-toggle="card-remove"
                    >
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                  <span className="pull-right">
                    <Button color="primary" onClick={() => setEdit(!editState)}>
                      {editState == true ? 'Edit' : 'DONE'}
                    </Button>
                  </span>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group mb-3">
                        <label htmlFor="contact_no" className="form-label">
                          Contact No
                        </label>
                        <input
                          disabled={editState}
                          id="contact_no"
                          className="form-control"
                          type="text"
                          value={state.contact_no}
                          onChange={(e) => onChange('contact_no', e)}
                          placeholder="Insert Contact No"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 ">
                      <div className="form-group mb-4">
                        <label className="form-label">{EmailAddress}</label>
                        <input
                          disabled={editState}
                          className="form-control"
                          value={state.email}
                          onChange={(e) => onChange('email', e)}
                          placeholder="your-email@domain.com"
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group mb-3">
                        <label className="form-label">Position</label>
                        <input
                          disabled={editState}
                          className="form-control"
                          type="text"
                          value={state.position}
                          onChange={(e) => onChange('position', e)}
                          placeholder="Position in the company"
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <label htmlFor="salutation">Work Status</label>
                      <Dropdown
                        disabled={editState}
                        className=""
                        isOpen={dropdownOpen2}
                        toggle={toggle2}
                      >
                        <DropdownToggle
                          disabled={editState}
                          className="form-control border"
                          color="default"
                          caret
                        >
                          {workStatus === 4
                            ? 'Probation'
                            : workStatus === 1
                            ? 'Confirmed'
                            : null}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={() => setWorkStatus(4)}>
                            Probation
                          </DropdownItem>
                          <DropdownItem onClick={() => setWorkStatus(1)}>
                            Confirmed
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                    <div className="col-sm-4">
                      <label className="col-form-label text-end pt-0">
                        Start Date:<span className="font-danger">{'*'}</span>
                      </label>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <DatePicker
                              disabled={editState}
                              className="form-control digits"
                              selected={startDate}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {workStatus === 4 ? (
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label htmlFor="title">
                            Probation End Date
                            <span className="font-danger">{'*'}</span>
                          </label>
                          <DatePicker
                            disabled={editState}
                            className="form-control digits"
                            selected={endDate}
                            onChange={handleChangeProbation}
                          />
                        </div>
                      </div>
                    ) : null}

                    <div className="row mt-1">
                      <div className="col-sm-6 col-md-4">
                        <div className="form-group mb-3">
                          <label className="form-label">
                            Monthly Gross Salary (MYR)
                          </label>
                          <input
                            disabled={editState}
                            id="salary"
                            className="form-control"
                            type="text"
                            value={state.salary}
                            onChange={(e) => onChange('salary', e)}
                            placeholder="RM5,000"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4">
                        <div className="form-group mb-3">
                          <label className="form-label">Office</label>
                          <input
                            disabled={editState}
                            id="office"
                            className="form-control"
                            type="text"
                            value={state.office}
                            onChange={(e) => onChange('office', e)}
                            placeholder="Office"
                          />
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-7">
                          <div className="col-12 form-group mb-3">
                            <label className="form-label">
                              Designated Bank Name
                            </label>
                            <Select
                              isDisabled={editState}
                              value={selectedBank}
                              onChange={handleSelectChange}
                              options={bankoptions}
                            />
                          </div>
                        </div>
                        <div className="col-sm-4 col-md-4">
                          <div className="form-group mb-3">
                            <label className="form-label">
                              Designated Bank Account No
                            </label>
                            <input
                              disabled={editState}
                              id="bank_account_no"
                              className="form-control"
                              type="text"
                              value={state.bank_account_no}
                              onChange={(e) => onChange('bank_account_no', e)}
                              placeholder="Bank Account No"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer text-end">
                  <button
                    className="btn btn-primary"
                    onClick={(e) => handleSubmit(e)}
                    type="submit"
                    disabled={editState}
                  >
                    {UpdateProfile}
                  </button>
                </div>
              </form>
            </div>
            {/* <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0">User Transactions</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table card-table table-vcenter text-nowrap">
                                        <thead>
                                            <tr>
                                                {UsersTableHeader.map((items, i) =>
                                                    <th key={i}>{items}</th>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((items, i) =>
                                                <tr key={i}>
                                                    <td><a className="text-inherit" href="#">{items.projectName} </a></td>
                                                    <td>{items.date}</td>
                                                    <td><span className="status-icon bg-success"></span>{items.status}</td>
                                                    <td>{items.price}</td>
                                                    <td className="text-end">
                                                        <button className="btn btn-primary btn-sm" href="javascript">
                                                            <i className="fa fa-pencil"></i> {Edit}
                                                        </button>
                                                        <button className="btn btn-transparent btn-sm" href="javascript">
                                                            <i className="fa fa-link"></i> {Update}
                                                        </button>
                                                        <button className="btn btn-danger btn-sm" href="javascript">
                                                            <i className="fa fa-trash"></i> {Delete}
                                                        </button>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EmployeeProfile;
