import { Home, Users, Activity, Settings, AlertCircle } from 'react-feather';

export const MENUITEMS = [
  {
    title: 'Dashboard',
    icon: Home,
    type: 'link',
    path: '/dashboard',
    badgeType: 'primary',
    active: false,
  },
  //Temp Off it
  // {
  //     title: 'Action', icon: AlertCircle, active: false, path: '/notification', type: 'link'
  // },
  {
    title: 'Transactions',
    icon: Activity,
    active: false,
    path: '/transactions',
    type: 'link',
  },
  {
    title: 'Users',
    icon: Users,
    type: 'sub',
    active: false,
    children: [
      { path: '/portalUser', type: 'link', title: 'Portal User' },
      { path: '/employee', type: 'link', title: 'Employee User' },
    ],
  },
  {
    title: 'Settings',
    icon: Settings,
    active: false,
    path: '/settings',
    type: 'link',
  },
];
