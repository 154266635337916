import React from 'react';

// Import custom Components 
import Homepage from '../components/dashboard/home';
import PortalUser from '../components/dashboard/PortalUser';
import EmployeeUser from '../components/dashboard/EmployeeUser';
import Settings from '../components/dashboard/Settings';
import ConsolidateFile from '../components/dashboard/Consolidate';
import EmployeeProfile from '../components/dashboard/EmployeeProfile';
import Notification from '../components/dashboard/Notifications';
export const routes = [
    { path: `${process.env.REACT_APP_PUBLIC_URL}/dashboard`, Component: <Homepage /> },
    { path: `${process.env.REACT_APP_PUBLIC_URL}/portalUser`, Component: <PortalUser /> },
    { path: `${process.env.REACT_APP_PUBLIC_URL}/employee`, Component: <EmployeeUser /> },
    { path: `${process.env.REACT_APP_PUBLIC_URL}/settings`, Component: <Settings /> },
    { path: `${process.env.REACT_APP_PUBLIC_URL}/notification`, Component: <Notification /> },
    { path: `${process.env.REACT_APP_PUBLIC_URL}/transactions`, Component: <ConsolidateFile /> },
    { path: `${process.env.REACT_APP_PUBLIC_URL}/profile/:id`, Component: <EmployeeProfile /> },
]
