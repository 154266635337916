import React, { useState, Fragment, useEffect } from 'react';
import DataTable from 'react-data-table-component';
// import Breadcrumb from '../common/breadcrumb';
import { Search } from 'react-feather';
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import API from '../../services/API';

export const columns = [
  {
    selector: (row) => moment.utc(row.created_at).format('DD/MM/YYYY'),
    name: 'Date',
    sortable: true,
    center: true,
  },
  {
    name: 'Employee ID',
    selector: (row) => row.employee_code,
    sortable: true,
    center: true,
  },
  {
    name: 'Employee Name',
    selector: (row) => row.full_name,
    sortable: true,
    center: true,
  },
  {
    name: 'Transaction ID',
    selector: (row) => row.id,
    sortable: true,
    center: false,
    minWidth: '300px',
  },
  {
    name: 'Status',
    selector: (row) => row.status,
    sortable: true,
    center: false,
  },

  {
    selector: (row) =>
      'RM ' +
      Number(row.amount)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,'),
    name: 'Amount',
    sortable: true,
    center: true,
  },
];

const ConsolidateFile = (props) => {
  var date = new Date();
  const [data, setData] = useState([]);
  const [searchType, setSearchType] = useState('0');
  const [query, setQuery] = useState('');
  const [startDate, setStartDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [format_start_date, setFormatStartDate] = useState(
    date.getFullYear() +
      '-' +
      (date.getMonth() < 12 ? date.getMonth() + 1 : 1) +
      '-' +
      1
  );
  const [format_end_date, setFormatEndDate] = useState(
    date.getFullYear() +
      '-' +
      (date.getMonth() < 12 ? date.getMonth() + 1 : 1) +
      '-' +
      date.getDate()
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleStartDate = (date) => {
    // console.log(date.getFullYear())
    // console.log(date.getMonth())
    // console.log(date.getDay())
    var the_formatted_date =
      date.getFullYear() +
      '-' +
      (date.getMonth() < 12 ? date.getMonth() + 1 : 1) +
      '-' +
      date.getDate();
    setFormatStartDate(String(the_formatted_date));
    setStartDate(date);
  };
  const handleEndDate = (date) => {
    var the_formatted_date =
      date.getFullYear() +
      '-' +
      (date.getMonth() < 12 ? date.getMonth() + 1 : 1) +
      '-' +
      date.getDate();
    setFormatEndDate(String(the_formatted_date));
    setEndDate(date);
  };
  var headers = {
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  };
  const FileDownload = require('js-file-download');
  useEffect(() => {
    API.post(
      '/disbursement-txns/search',
      {
        start_date: format_start_date,
        end_date: format_end_date,
        search_type: searchType,
        search_value: query,
      },
      { headers }
    ).then(({ data }) => {
      if (data.response_code == 1000) {
        setData(data.data);
      } else {
        setData([]);
        console.log('No data found');
      }
    });
  }, [format_start_date, format_end_date]);

  const onClickDownload = () => {
    var headers = {
      responseType: 'blob',
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    };
    API.post(
      '/disbursement-txns/download',
      {
        start_date: format_start_date,
        end_date: format_end_date,
        search_type: searchType,
        search_value: query,
      },
      { headers, responseType: 'blob' }
    )
      .then((response) => {
        // FileDownload(data, 'Excel.csv');
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Disbursement_List.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log('Error: ', err);
      });
  };
  const onSearch = () => {
    if (query != '') {
      if (searchType == '0') {
        setSearchType('2');
        API.post(
          '/disbursement-txns/search',
          {
            start_date: format_start_date,
            end_date: format_end_date,
            search_type: '2',
            search_value: query,
          },
          { headers }
        ).then(({ data }) => {
          if (data.response_code == 1000) {
            setData(data.data);
          } else {
            setData([]);
            console.log('No data');
          }
        });
      } else {
        API.post(
          '/disbursement-txns/search',
          {
            start_date: format_start_date,
            end_date: format_end_date,
            search_type: searchType,
            search_value: query,
          },
          { headers }
        ).then(({ data }) => {
          console.log(data);
          if (data.response_code == 1000) {
            setData(data.data);
          } else {
            setData([]);
            console.log('No data found');
          }
        });
      }
    } else {
      API.post(
        '/disbursement-txns/search',
        {
          start_date: format_start_date,
          end_date: format_end_date,
          search_type: '0',
          query: '',
        },
        { headers }
      ).then(({ data }) => {
        console.log(data);
        if (data.response_code == 1000) {
          setData(data.data);
        } else {
          setData([]);
          console.log('No data found');
        }
      });
    }
  };
  const onClear = () => {
    setSearchType('0');
    setQuery('');
    setStartDate(new Date(date.getFullYear(), date.getMonth(), 1));
    setEndDate(new Date());
    var start_date = new Date(date.getFullYear(), date.getMonth(), 1);
    var end_date = new Date();
    var the_formatted_start_date =
      start_date.getFullYear() +
      '-' +
      (start_date.getMonth() < 12 ? start_date.getMonth() + 1 : 1) +
      '-' +
      start_date.getDate();
    var the_formatted_end_date =
      end_date.getFullYear() +
      '-' +
      (end_date.getMonth() < 12 ? end_date.getMonth() + 1 : 1) +
      '-' +
      end_date.getDate();
    setFormatStartDate(String(the_formatted_start_date));
    setFormatEndDate(String(the_formatted_end_date));
    API.post(
      '/disbursement/search',
      {
        start_date: String(the_formatted_start_date),
        end_date: String(the_formatted_end_date),
        search_type: '0',
        query: '',
      },
      { headers }
    ).then(({ data }) => {
      if (data.response_code == 1000) {
        setData(data.data);
      } else {
        setData([]);
        console.log('No data found');
      }
    });
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      onSearch();
    }
  }

  return (
    <Fragment>
      {/*<Breadcrumb parent="Dashboard" title="Transactions" />*/}
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 xl-100">
            <div className="row">
              <div className="col-xl-12 xl-100">
                <div className="card rounded-25">
                  <div className="card-body p-0">
                    <div className="col-12 card-header rounded-25 server-header space-evenly pl-5 pr-5">
                      <div className="col-7 form-group form-row justify-content-start ">
                        <div className="form-group form-row m-0 p-2">
                          <label className=" col-form-label text-end">
                            Start Date:
                          </label>
                          <div className="">
                            <div className="input-group">
                              <DatePicker
                                className="form-control digits"
                                selected={startDate}
                                onChange={handleStartDate}
                                dateFormat="dd/MM/yyyy"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group form-row m-0 p-2">
                          <label className="col-form-label text-end">
                            End Date:
                          </label>
                          <div className="">
                            <div className="input-group">
                              <DatePicker
                                className="form-control digits"
                                selected={endDate}
                                onChange={handleEndDate}
                                dateFormat="dd/MM/yyyy"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-5 form-group form-row justify-content-end m-0">
                        <div className="col-xxl-4 ">
                          <Dropdown
                            className=""
                            isOpen={dropdownOpen}
                            toggle={toggle}
                          >
                            <DropdownToggle
                              className="nav-link"
                              tag="a"
                              href="#"
                              color="#168A53"
                              style={{ color: '#168A53' }}
                              caret
                            >
                              {searchType == '2' || searchType == '0'
                                ? 'Employee Name'
                                : 'Employee ID'}
                            </DropdownToggle>
                            <DropdownMenu style={{ color: '#168A53' }}>
                              <DropdownItem onClick={() => setSearchType('2')}>
                                Employee Name
                              </DropdownItem>
                              <DropdownItem onClick={() => setSearchType('1')}>
                                Employee ID
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                        <div className="col-8 d-flex">
                          <div className="job-filter">
                            <div className="faq-form">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Search.."
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                onKeyDown={handleKeyDown}
                              />
                              <Search className="search-icon" />
                            </div>
                          </div>
                          &nbsp;&nbsp;
                          <Button
                            color="primary"
                            className=""
                            name="basic"
                            onClick={() => onSearch()}
                          >
                            Search
                          </Button>
                          {/* <Button color="danger" outline onClick={() => onClear()}>Clear</Button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 xl-100">
                <div className="card rounded-25">
                  <div className="card-body">
                    <div className="table-responsive">
                      <DataTable
                        columns={columns}
                        data={data}
                        striped={true}
                        center={true}
                        persistTableHead
                        pagination
                      />
                      <Button
                        color="primary"
                        className=""
                        name="basic"
                        onClick={() => onClickDownload()}
                      >
                        Download CSV
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ConsolidateFile;
