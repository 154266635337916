import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';
import { useTranslation } from 'react-i18next';
import logo_compact from '../../../assets/images/onde-logo-s.png';

const FooterPanel = () => {
    const support_link = '#';
    const full_name=localStorage.getItem('full_name')
    const position=localStorage.getItem('position')
    const { t } = useTranslation();

    return (
        <Fragment>
            <div className="sidebar-footer text-center">
                <div>
                    <img className="img-40 lazyloaded blur-up" src={logo_compact} alt="#" />
                </div>
                <a href={support_link}>{t('Need help? Contact Us')}</a>
            </div>
        </Fragment>
    );
};

export default FooterPanel;